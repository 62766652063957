import selfServiceAdapter from "./self-service-adapter"; // innlogget
// note: need to use open-web-self-service-proxied when implementing "Fortsett som gjest"

//Not logged in, need this for the prepaid payments.
import selfServiceSystemAdapter from "~/integrations/self-service-system-adapter";
const systemUserApi = selfServiceSystemAdapter("/netspayment/v1");

const api = selfServiceAdapter("/netspayment/v1");

export const fetchPaymentUrl = async (body) =>
	api.post(`/payments/`, body, {
		cache: { override: true },
	});

export const cancelPayment = async (paymentReference) =>
	api.post(`/payments/${paymentReference}/cancel/`, {
		cache: { override: true },
	});

export const fetchPaymentUrlForNotLoggedIn = async (body) =>
	systemUserApi.post(`/payments/`, body, {
		cache: { override: true },
	});
